<template>
	<div class="data-content">
		<div class="top-box">
			<div>
				<button class="add-btn" @click="handleEditUser('add')" v-if="perms.includes('train:student:add')">新增</button>
				<button class="import-btn" @click="handleImport" v-if="perms.includes('train:student:import')">导入</button>
				<input style="display:none;" ref="uploadExcel" type="file" id="uploadExcel" @change="uploadHandle($event)">
				<button class="import-btn" @click="handleExport" v-if="perms.includes('train:student:export')">导出</button>
				<button class="template-btn" @click="handleTemplate" v-if="perms.includes('train:student:import')">模板下载</button>
			</div>
			<div class="top-right-box">
				<label class="label">关键字</label>
				<el-input class="search-input" size="small" type="text" v-model="searchKey" placeholder="请输入关键字"></el-input>
				<label class="label">区域</label>
				<el-select class="search-input" size="small" v-model="areaId" filterable placeholder="请选择">
					<el-option value="" label="全部"></el-option>
					<el-option v-for="(item,i) in areaList" :key="i" :value="item.id" :label="item.name"></el-option>
				</el-select>
				<label class="label">有效性</label>
				<el-select class="search-input" size="small" v-model="active" filterable placeholder="请选择">
					<el-option value="" label="全部"></el-option>
					<el-option value="1" label="有效"></el-option>
					<el-option value="0" label="失效"></el-option>
				</el-select>
				<img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="getUserList">
				<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
			</div>
		</div>
		<div class="data-list-box">
			 <el-table
				:data="userList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				border
				style="width: 100%">
				<el-table-column
					label="NO."
					width="60">
					<template slot-scope="scope">
						<span class="no">{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="name"
					show-overflow-tooltip
					label="学员">
				</el-table-column>
				<el-table-column
					prop="stuId"
					show-overflow-tooltip
					label="学员代号">
				</el-table-column>
				<el-table-column
					prop="agentName"
					show-overflow-tooltip
					label="公司名称">
				</el-table-column>
				<el-table-column
					prop="agentCode"
					show-overflow-tooltip
					label="公司代号">
				</el-table-column>
				<el-table-column
					prop="areaName"
					show-overflow-tooltip
					label="区域">
				</el-table-column>
				<el-table-column
					label="有效性"
					align="center"
					width="70">
					<template slot-scope="scope">
						<span v-if="scope.row.active == 1">Y</span>
						<span v-if="scope.row.active == 0" style="color:red;">N</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="createBy"
					show-overflow-tooltip
					label="创建者">
				</el-table-column>
				<el-table-column
					prop="createTime"
					show-overflow-tooltip
					label="创建时间">
				</el-table-column>
				<el-table-column
					label="操作"
					width="130">
					<template slot-scope="scope">
						<button class="btn-blue" @click="handleEditUser('check',scope.row)">查看</button>
						<button class="btn-blue" @click="handleEditUser('edit',scope.row)" v-if="perms.includes('train:student:update')">编辑</button>
						<button class="btn-blue" @click="handleEditUserStatus(scope.row)" v-if="perms.includes('train:student:active') && scope.row.active == 0">激活</button>
						<button class="btn-red" @click="handleEditUserStatus(scope.row)" v-if="perms.includes('train:student:invalid') && scope.row.active == 1">失效</button>
						<button class="btn-red" @click="handleDeleteData(scope.row)" v-if="perms.includes('train:student:delete') && scope.row.active == 0">删除</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
		<edit-user ref="editUser" @getUserList="getUserList"></edit-user>
		<el-dialog
			:visible.sync="visible"
			:show-close="false"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			class="role-dialog"
			width="450px">
			<span slot="title" class="dialog-title">
				<span>提示</span>
				<img src="@/assets/images/close.png" alt="" @click="visible = false">
			</span>
			<ul class="error-list">
				<li v-for="(item,i) in errorList" :key="i">{{item}}</li>
			</ul>
		</el-dialog>
	</div>
</template>
<script>
import EditUser from './components/edit-user'
import Pagination from '@/components/Pagination'
export default {
	components: {
		EditUser,
		Pagination
	},
	data() {
		return {
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			searchKey: '',
			areaId: '',
			active: '',
			areaList: [],
			pageNo: 1,
			pageSize: 20,
			total: 0,
			userList: [],
			visible: false,
			errorList: []
		}
	},
	created() {
		this.getAreaList();
		this.getUserList();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 新增/编辑/查看角色
		handleEditUser(type,data) {
			this.$refs.editUser.init(type,data);
		},
		// 获取区域
		getAreaList() {
			let that = this;
			that.$request.post(
				"sysDataDetailPageList",
				false,
				{
					pageNo: 1,
					pageSize: 999,
					code: 'areaType'
				},
				function (r) {
					if (r.code == "0") {
						that.areaList = r.data.list;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleSizeChange() {
			this.getUserList();
		},
		handleCurrentChange() {
			this.getUserList();
		},
		handleSearch() {
			this.pageNo = 1;
			this.getUserList();
		},
		// 重置查询条件
		handleReset() {
			this.searchKey = '';
			this.areaId = '';
			this.active = '';
			this.pageNo = 1;
			this.getUserList();
		},
		// 获取列表数据
		getUserList() {
			let that = this;
			that.$request.get(
				"studentList",
				true,
				{
					searchKey: that.searchKey,
					areaId: that.areaId,
					active: that.active,
					pageNo: that.pageNo,
					pageSize: that.pageSize
				},
				function (r) {
					if (r.code == "0") {
						that.userList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 更改用户状态
		handleEditUserStatus(dt) {
			let tips = dt.active == 1 ? '是否失效当前用户?' : '是否激活当前用户?';
			let statusUrl = dt.active == 1 ? 'invalidStudent' : 'activeStudent';
			this.$confirm(tips, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					statusUrl,
					true,
					{
						id: dt.id
					},
					function (r) {
						if (r.code == "0") {
							that.getUserList();
							that.$message.success('用户状态已变更');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		// 删除
		handleDeleteData(data) {
			this.$confirm('是否删除当前用户?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'sysUserDelete',
					true,
					{
						id: data.id
					},
					function (r) {
						if (r.code == "0") {
							that.getUserList();
							that.$message.success('删除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		// 导入
		handleImport() {
			let el = document.getElementById('uploadExcel')
            el.click();
		},
		// 上传
		uploadHandle(e) {
			let file = e.target.files[0];
            if(file){
				if(window.sessionStorage.getItem('userInfo').superAdminFlag == '1' && file.size/1024/1024/1024 > 1) {
					this.$message.error('导入文件不能大于1GB');
					return
				}
				// 2022-07-31 应客户要求改为200M
				if(window.sessionStorage.getItem('userInfo').superAdminFlag != '1' && file.size/1024/1024 > 200) {
					this.$message.error('导入文件不能大于200MB');
					return
				}
				if(file.name.includes('.xlsx')){
					let formdata = new FormData();
					formdata.append('file',file);
					let that = this;
					that.$request.initUploadExcel(
						formdata,
						'studentUpload',
						true
					)
					.then((data) => {
						if(data.code == 0) {
							that.pageNo = 1;
							that.getUserList();
							if(data.data.length > 0) {
								that.visible = true;
								that.errorList = data.data;
							}else {
								that.$message.success('导入成功');
							}
						}
					})
				}else {
					this.$message.error('文件格式不正确');
				}
			}else{
				this.$message.error('请选择上传的文件');
			}
			this.$refs.uploadExcel.value = '';
		},
		// 导出
		handleExport() {
			let that = this
			that.$request.get(
				"studentDownload",
				true,
				{
					searchKey: that.searchKey,
					active: that.active,
					areaId: that.areaId
				},
				function (res) {
					if (res.code == '0') {
						const linkNode = document.createElement('a');
						linkNode.style.display = 'none';
						linkNode.href = res.data;
						document.body.appendChild(linkNode);
						linkNode.click();  //模拟在按钮上的一次鼠标单击
						URL.revokeObjectURL(linkNode.href); // 释放URL 对象
						document.body.removeChild(linkNode);
					} else {
						that.$message.error(res.msg)
					}
				}
			)
		},
		// 模板下载
		handleTemplate() {
			const linkNode = document.createElement('a');
			linkNode.style.display = 'none';
			linkNode.href = '/static/template/STUDENT-DATA.xlsx';
			linkNode.download = 'STUDENT-DATA.xlsx';
			document.body.appendChild(linkNode);
			linkNode.click();  //模拟在按钮上的一次鼠标单击
			URL.revokeObjectURL(linkNode.href); // 释放URL 对象
			document.body.removeChild(linkNode);
		}
	}
}
</script>
<style lang="scss" scoped>
.block{
	display: block;
}
.data-content{
	.top-box{
		// height: 32px;
		margin-bottom: 15px;
	}
	.add-btn{
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.import-btn{
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.template-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.top-right-box{
		// float: right;
		text-align: right;
		margin-top: 15px;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.search-input{
			width: 138px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
		.refresh-btn{
			width: 32px;
			height: 32px;
			border: none;
			color: #117FFC;
			padding: 0;
			background-color: rgba(17, 127, 252, 0.2);
			font-weight: bold;
			font-size: 22px;
			vertical-align: bottom;
			margin-left: 5px;
		}
	}
	.btn-blue{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.error-list{
	height: 50vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: auto;
	li{
		list-style: none;
	}
}
</style>
<style>
	.el-message .user-message{
		margin: 15px;
	}
</style>
